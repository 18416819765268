<template>
  <div>
    <!-- MOBILE -->
    <div v-if="isMobileWidth === 1" class="mb-10 pb-5">
      <div v-if="isLoading" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
        <v-card
          class="round mb-5"
          elevation="0"
          outlined
          width="100%"
          v-for="n in 3"
            :key="n"
            :ref="'Load'+n"
        >
          <v-card-text class="pa-2 pb-0">
            <v-skeleton-loader
              type="card"
            ></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </div>
      <div v-else >
        <div v-if="listQuotations.length" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
          <v-card
            color="primary"
            width="100%"
            class="round mb-5"
            outlined
            v-for="list in listQuotations" :key="list.id"
          >
            <v-card-text class="title p-0 white--text pb-3">
              <p v-if="list.quotation.name.length < 37" class="font-weight-bold mb-0">{{ list.quotation.name }}</p>
              <p v-else class="font-weight-bold mb-0">{{list.quotation.name.substring(0,37)+".."}} - {{ list.quotation.date_added }}</p>
              <div class="d-flex justify-space-between">
                <v-chip v-if="list.quotation.status === 1" color="error"><span class="text-12">Belum Memilih</span></v-chip>
                <v-chip v-else-if="list.quotation.status === 2" color="warning"><span class="text-12">Telah Memilih</span></v-chip>
                <v-chip v-else color="success">Selesai</v-chip>
                <p class="text-12 mb-0 mt-0">{{ list.quotation.date_added.substring(0,10) }}</p>
              </div>
            </v-card-text>
            <v-card-text v-for="detail in list.quotation_detail" :key="detail.id" class="white--text">
              <p class="mb-1 font-weight-bold">{{ (detail.level === 'A') ? 'Nano' : 'Micro' }} Influencer</p>
              <p class="mb-1"><i class="fas fa-user mr-2"></i>{{ detail.qty }} Influencer</p>
              <p v-if="detail.platform === 'instagram'" class="mb-1 text-capitalize"><i class="fab fa-instagram mr-2"></i>{{ detail.platform }} </p>
              <p v-else class="mb-1 text-capitalize"><i class="fab fa-tiktok mr-2"></i>{{ detail.platform }} </p>
              <p class="mb-1 text-capitalize"><i class="fas fa-mobile-alt mr-2 ml-1"></i>{{ detail.format | labelFormat }} </p>
            </v-card-text>
            <v-card-text>
              <v-btn v-if="list.quotation.status === 1"
                color="white"
                block
                rounded
                class="text-capitalize primary--text"
                @click="openDetail(list.quotation.id)"
              >
                <i class="fa fa-user-check mr-2" aria-hidden="true"></i> Pilih Influencer
              </v-btn>
              <div v-else-if="list.quotation.status === 2">
                <v-btn
                  color="white"
                  block
                  rounded
                  class="text-capitalize primary--text"
                  @click="openDetail(list.quotation.id)"
                >
                  <i class="fa fa-eye mr-2" aria-hidden="true"></i> Lihat Detail
                </v-btn>
                <v-btn
                  color="white"
                  block
                  rounded
                  outlined
                  class="text-capitalize white--text mt-2"
                  @click="goToReport(list.quotation.id)"
                >
                  <i class="far fa-file mr-2"></i> Lihat Report
                </v-btn>
              </div>
              <v-btn v-else
                  color="white"
                  block
                  rounded
                  class="text-capitalize primary--text"
                  @click="goToReport(list.quotation.id)"
                >
                  <i class="far fa-file mr-2"></i> Lihat Report
                </v-btn>
            </v-card-text>
            <!-- <v-card-text>
              <p class="font-weight-semibold mb-0"><i class="fas fa-tag mr-1"></i> IDR {{ list.price | numberFormat }}</p>
              <div class="row">
                <v-col cols="6">
                  <v-btn @click="buy(list.name)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-shopping-cart mr-2"></i>Buy Package</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn @click="openDetail(list.id)" elevation="0" block outlined rounded class="text-capitalize mb-2 mt-2" small color="primary">Lihat Detail</v-btn>
                </v-col>
              </div>
            </v-card-text> -->
          </v-card>
        </div>

        <!-- CAMPAIGN KOSONG -->
        <div v-else class="black--text">
          <p class="text-center mt-5">Anda belum memiliki campaign</p>
          <p class="text-center">Mulai campaign Anda dengan memilih paket yang tersedia atau menghubungi kami melalui <span class="font-weight-semibold primary--text pointer" @click="goToWa()">WhatsApp</span> untuk paket custom.</p>
          <center>
            <v-btn @click="goToPackage()" rounded color="primary" class="text-capitalize">Lihat Paket</v-btn>
          </center>
        </div>
      </div>
    </div>

    <!-- DESKTOP -->
    <div v-else class="mb-10 pb-5">
      <div v-if="isLoading" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
        <v-card
          class="round mb-5"
          elevation="0"
          outlined
          width="100%"
          v-for="n in 3"
            :key="n"
            :ref="'Load'+n"
        >
          <v-card-text class="pa-2 pb-0">
            <v-skeleton-loader
              type="card"
            ></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </div>
      <div v-else>
        <div v-if="listQuotations.length > 0" class="row d-flex justify-space-between pt-3">
          <v-col class="pa-0" cols="3" v-for="list in listQuotations" :key="list.id">
            <v-card
              height=""
              class="round ma-3"
              elevation="0"
              outlined
            >
              <v-card-text class="title p-0 black--text pb-3">
                <p v-if="list.quotation.name.length < 37" class="font-weight-bold mb-0">{{ list.quotation.name }}</p>
                <p v-else class="font-weight-bold mb-0">{{list.quotation.name.substring(0,37)+".."}} - {{ list.quotation.date_added }}</p>
                <div class="d-flex justify-space-between">
                  <v-chip v-if="list.quotation.status === 1" color="error"><span class="text-12">Belum Memilih</span></v-chip>
                  <v-chip v-else-if="list.quotation.status === 2" color="warning"><span class="text-12">Telah Memilih</span></v-chip>
                  <v-chip v-else color="success">Selesai</v-chip>
                  <p class="text-12 mb-0 mt-0">{{ list.quotation.date_added.substring(0,10) }}</p>
                </div>
              </v-card-text>
              <v-card-text v-for="detail in list.quotation_detail" :key="detail.id" class="black--text">
                <p class="mb-1 font-weight-bold">{{ (detail.level === 'A') ? 'Nano' : 'Micro' }} Influencer</p>
                <p class="mb-1"><i class="fas fa-user mr-2"></i>{{ detail.qty }} Influencer</p>
                <p v-if="detail.platform === 'instagram'" class="mb-1 text-capitalize"><i class="fab fa-instagram mr-2"></i>{{ detail.platform }} </p>
                <p v-else class="mb-1 text-capitalize"><i class="fab fa-tiktok mr-2"></i>{{ detail.platform }} </p>
                <p class="mb-1 text-capitalize"><i class="fas fa-mobile-alt mr-2 ml-1"></i>{{ detail.format | labelFormat }} </p>
              </v-card-text>
              <v-card-text>
                <v-btn v-if="list.quotation.status === 1"
                  color="primary"
                  block
                  rounded
                  class="text-capitalize white--text"
                  @click="openDetail(list.quotation.id)"
                >
                  <i class="fa fa-user-check mr-2" aria-hidden="true"></i> Pilih Influencer
                </v-btn>
                <div v-else-if="list.quotation.status === 2">
                  <v-btn
                    color="primary"
                    block
                    rounded
                    class="text-capitalize white--text"
                    @click="openDetail(list.quotation.id)"
                  >
                    <i class="fa fa-eye mr-2" aria-hidden="true"></i> Lihat Detail
                  </v-btn>
                  <v-btn
                    color="primary"
                    block
                    rounded
                    outlined
                    class="text-capitalize mt-3"
                    @click="goToReport(list.quotation.id)"
                  >
                    <i class="far fa-file mr-2"></i> Lihat Report
                  </v-btn>
                </div>
                <v-btn v-else
                  color="primary"
                  block
                  rounded
                  outlined
                  class="text-capitalize mt-3"
                  @click="goToReport(list.quotation.id)"
                >
                  <i class="far fa-file mr-2"></i> Lihat Report
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </div>
        <!-- CAMPAIGN KOSONG -->
        <div v-else class="black--text subtitle-1">
          <p class="text-center mt-5">Anda belum memiliki campaign</p>
          <p class="text-center">Mulai campaign Anda dengan memilih paket yang tersedia atau menghubungi kami melalui <span class="font-weight-semibold primary--text pointer" @click="goToWa()">WhatsApp</span> untuk paket custom.</p>
          <center>
            <v-btn @click="goToPackage()" rounded color="primary" class="text-capitalize">Lihat Paket</v-btn>
          </center>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props:['data'],
  setup() {
    return {
      isLoading:true,
      isMobileWidth:0,
      listQuotations:[]
    }
  },
  mounted(){
    this.page = this.$route.query.item
    if(this.isMobileWidth === 0){
      if (localStorage.getItem('reloaded')) {
          localStorage.removeItem('reloaded');
          this.reload = false
      } else {
          localStorage.setItem('reloaded', '1');
          location.reload();
      }
    }
    window.scrollTo(0,0);

    this.loadPackages()
    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  methods:{
    encryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg='
      return this.$CryptoJS.AES.encrypt(val.toString(), secretKey).toString();
    },
    loadPackages(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'quotation', {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response){
            this.listQuotations = response.data.data
            setTimeout(() => {
              this.isLoading = false
            }, 500);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    openDetail(id){
      this.$router.push({name:'campaign-detail', query: {item: this.encryptData(id)}});
    },
    goToReport(id){
      this.$router.push({name:'campaign-report', query: {item: this.encryptData(id)}});
    },
    goToPackage(){
      this.$router.push({name:'package-all'});
    },
    goToWa(){
      window.open('https://wa.me/6285691701929?text=Halo Admin, Saya ingin menggunakan paket custom. Mohon diinfo prosedurnya ya. \n Terima kasih', '_blank');
    },
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    labelFormat (value) {
      return value.replace(/-/g,' ');
    }
  }
}
</script>
